<template>
  <div>
    <h6>{{ $t("HIGHLIGHT") }}</h6>

    <div class="row mb-4">
      <div class="col-lg-4 mb-5" id="treeLeaves">
        <a
          class="card bg-dark text-white"
          :href="`/viewmatch?id=${highlight[0].id}`"
        >
          <img
            class="card-img"
            src="img/soccer-card.png"
            alt="Card image"
            height="140px"
          />
          <div class="card-img-overlay">
            <p class="card-text text-center">
              {{ timeReturn(highlight[0].startAt) }}
            </p>

            <h5 class="card-title text-center text-white">
              {{ highlight[0].homeTeamName }} VS {{ highlight[0].awayTeamName }}
            </h5>
            <p class="card-text text-center">
              <img
                class="card-img mr-1 bg-light rounded"
                :src="highlight[0].leagueLogoUrl"
                alt="Card image"
                style="width: 20px; height: 20px; object-fit: cover"
              />
              {{ highlight[0].leagueName }}
            </p>
            <h4 class="card-text text-center">
              <span class="badge badge-pill badge-primary">
                {{ highlight[0].totalZianPrediction }} TIPS
              </span>
            </h4>
          </div>
        </a>
      </div>
      <div class="col-lg-4 mb-5" id="treeLeaves">
        <a
          class="card bg-dark text-white"
          :href="`/viewmatch?id=${highlight[1].id}`"
        >
          <img
            class="card-img"
            src="img/soccer-card.png"
            alt="Card image"
            height="140px"
          />
          <div class="card-img-overlay">
            <p class="card-text text-center">
              {{ timeReturn(highlight[1].startAt) }}
            </p>
            <h5 class="card-title text-center text-white">
              {{ highlight[1].homeTeamName }} VS {{ highlight[1].awayTeamName }}
            </h5>
            <p class="card-text text-center">
              <img
                class="card-img mr-1 bg-light rounded"
                :src="highlight[0].leagueLogoUrl"
                alt="Card image"
                style="width: 20px; height: 20px; object-fit: cover"
              />
              {{ highlight[1].leagueName }}
            </p>
            <h4 class="card-text text-center">
              <span class="badge badge-pill badge-primary">
                {{ highlight[1].totalZianPrediction }} TIPS</span
              >
            </h4>
          </div>
        </a>
      </div>
      <div class="col-lg-4 mb-5" id="treeLeaves">
        <a
          class="card bg-dark text-white"
          :href="`/viewmatch?id=${highlight[2].id}`"
        >
          <img
            class="card-img"
            src="img/soccer-card.png"
            alt="Card image"
            height="140px"
          />
          <div class="card-img-overlay">
            <p class="card-text text-center">
              {{ timeReturn(highlight[2].startAt) }}
            </p>
            <h5 class="card-title text-center text-white">
              {{ highlight[2].homeTeamName }} VS {{ highlight[2].awayTeamName }}
            </h5>
            <p class="card-text text-center">
              <img
                class="card-img mr-1 bg-light rounded"
                :src="highlight[0].leagueLogoUrl"
                alt="Card image"
                style="width: 20px; height: 20px; object-fit: cover"
              />
              {{ highlight[2].leagueName }}
            </p>
            <h4 class="card-text text-center">
              <span class="badge badge-pill badge-primary">
                {{ highlight[2].totalZianPrediction }} TIPS</span
              >
            </h4>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/fixtures/highlight`)
      .then(({ data }) => {
        this.highlight = data.data;
      })
      .catch((err) => {
        return;
      });
  },
  data() {
    return {
      highlight: {},
    };
  },
  methods: {
    timeReturn(val) {
      return (
        new Date(val).toDateString() +
        " " +
        new Date(val).toTimeString().split(" ")[0].split(":")[0] +
        ":" +
        new Date(val).toTimeString().split(" ")[0].split(":")[1]
      );
    },
  },
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
</style>
