<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 mb-3 rounded"
    >
      <h6 class="m-0 text-white">{{ $t("TOP_LEAGUES") }}</h6>
    </div>
    <div class="card p-1">
      <a
        class="d-flex mb-2 top-league"
        v-for="data in leagues"
        :key="data"
        style="
          cursor: pointer;
          text-decoration: none;
          border: 1px solid rgb(227, 227, 227);
          border-radius: 2px;
        "
        :href="`/upcomingevents?id=${data.id}`"
      >
        <img
          :src="data.logoUrl"
          style="width: 40px; height: 40px; object-fit: cover"
        />
        <div class="py-2 px-2" style="width: 100%">
          <p class="m-0">
            {{ data.name }}
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/leagues`)
      .then(({ data }) => {
        this.leagues = data.data;
        console.log("leagues", this.leagues);
      })
      .catch((err) => {
        return;
      });
  },
  data() {
    return {
      leagues: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
.top-league:hover {
  background-color: #dadada !important;
}
</style>
