<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <!-- Topbar Start -->
  <div>
    <!-- Navbar End -->
    <!-- <ViewMatch /> -->
    <div class="container-fluid py-3">
      <div class="card p-4">
        <HighLight />

        <div class="row">
          <div class="col-lg-2">
            <TopLeague />
            <a
              :href="'/register'"
              style="cursor: pointer"
              class="d-none d-lg-block"
            >
              <img
                class="card-img mt-2"
                src="img/ads-2.png"
                style="position: sticky; top: 5px"
            /></a>
          </div>

          <div class="col-lg-6">
            <Upcoming />

            <!-- <carousel :items="1" :navText="['<', '>']">
              <div
                class="position-relative overflow-hidden"
                style="height: 435px"
              >
                <img
                  class="img-fluid h-100"
                  src="img/news-700x435-1.jpg"
                  style="object-fit: cover"
                />
                <div class="overlay">
                  <div class="mb-1">
                    <a class="text-white" href="">Technology</a>
                    <span class="px-2 text-white">/</span>
                    <a class="text-white" href="">January 01, 2045</a>
                  </div>
                  <a class="h2 m-0 text-white font-weight-bold" href=""
                    >Sanctus amet sed amet ipsum lorem. Dolores et erat et elitr
                    sea sed</a
                  >
                </div>
              </div>
              <div
                class="position-relative overflow-hidden"
                style="height: 435px"
              >
                <img
                  class="img-fluid h-100"
                  src="img/news-700x435-2.jpg"
                  style="object-fit: cover"
                />
                <div class="overlay">
                  <div class="mb-1">
                    <a class="text-white" href="">Technology</a>
                    <span class="px-2 text-white">/</span>
                    <a class="text-white" href="">January 01, 2045</a>
                  </div>
                  <a class="h2 m-0 text-white font-weight-bold" href=""
                    >Sanctus amet sed amet ipsum lorem. Dolores et erat et elitr
                    sea sed</a
                  >
                </div>
              </div>
            </carousel> -->
          </div>
          <div class="col-lg-4">
            <MostPlayed />
            <img
              class="card-img"
              src="img/ads-1.png"
              style="position: sticky; top: 5px"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Main News Slider End -->
    <!-- Top News Slider Start -->
    <!-- <div class="container-fluid py-3">
      <div class="container">
        <carousel :navText="['<', '>']">
          <div class="d-flex">
            <img
              src="img/news-100x100-1.jpg"
              style="width: 80px; height: 80px; object-fit: cover"
            />
            <div
              class="d-flex align-items-center bg-light px-3"
              style="height: 80px"
            >
              <a class="text-secondary font-weight-semi-bold" href=""
                >Lorem ipsum dolor sit amet consec adipis elit</a
              >
            </div>
          </div>
          <div class="d-flex">
            <img
              src="img/news-100x100-2.jpg"
              style="width: 80px; height: 80px; object-fit: cover"
            />
            <div
              class="d-flex align-items-center bg-light px-3"
              style="height: 80px"
            >
              <a class="text-secondary font-weight-semi-bold" href=""
                >Lorem ipsum dolor sit amet consec adipis elit</a
              >
            </div>
          </div>
          <div class="d-flex">
            <img
              src="img/news-100x100-3.jpg"
              style="width: 80px; height: 80px; object-fit: cover"
            />
            <div
              class="d-flex align-items-center bg-light px-3"
              style="height: 80px"
            >
              <a class="text-secondary font-weight-semi-bold" href=""
                >Lorem ipsum dolor sit amet consec adipis elit</a
              >
            </div>
          </div>
          <div class="d-flex">
            <img
              src="img/news-100x100-4.jpg"
              style="width: 80px; height: 80px; object-fit: cover"
            />
            <div
              class="d-flex align-items-center bg-light px-3"
              style="height: 80px"
            >
              <a class="text-secondary font-weight-semi-bold" href=""
                >Lorem ipsum dolor sit amet consec adipis elit</a
              >
            </div>
          </div>
        </carousel>
      </div>
    </div> -->

    <!-- Top News Slider End -->
    <!-- News With Sidebar Start -->
    <!-- <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="row mb-3">
              <div class="col-12">
                <div
                  class="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3"
                >
                  <h3 class="m-0">Popular</h3>
                  <a
                    class="text-secondary font-weight-medium text-decoration-none"
                    href=""
                    >View All</a
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="position-relative mb-3">
                  <img
                    class="img-fluid w-100"
                    src="img/news-500x280-2.jpg"
                    style="object-fit: cover"
                  />
                  <div class="overlay position-relative bg-light">
                    <div class="mb-2" style="font-size: 14px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h4" href=""
                      >Est stet amet ipsum stet clita rebum duo</a
                    >
                    <p class="m-0">
                      Rebum dolore duo et vero ipsum clita, est ea sed duo diam
                      ipsum, clita at justo, lorem amet vero eos sed sit...
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-1.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-2.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="position-relative mb-3">
                  <img
                    class="img-fluid w-100"
                    src="img/news-500x280-3.jpg"
                    style="object-fit: cover"
                  />
                  <div class="overlay position-relative bg-light">
                    <div class="mb-2" style="font-size: 14px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h4" href=""
                      >Est stet amet ipsum stet clita rebum duo</a
                    >
                    <p class="m-0">
                      Rebum dolore duo et vero ipsum clita, est ea sed duo diam
                      ipsum, clita at justo, lorem amet vero eos sed sit...
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-3.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-4.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3 pb-3">
              <a href=""
                ><img class="img-fluid w-100" src="img/3319496_1.png" alt=""
              /></a>
            </div>

            <div class="row">
              <div class="col-12">
                <div
                  class="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3"
                >
                  <h3 class="m-0">Latest</h3>
                  <a
                    class="text-secondary font-weight-medium text-decoration-none"
                    href=""
                    >View All</a
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="position-relative mb-3">
                  <img
                    class="img-fluid w-100"
                    src="img/news-500x280-5.jpg"
                    style="object-fit: cover"
                  />
                  <div class="overlay position-relative bg-light">
                    <div class="mb-2" style="font-size: 14px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h4" href=""
                      >Est stet amet ipsum stet clita rebum duo</a
                    >
                    <p class="m-0">
                      Rebum dolore duo et vero ipsum clita, est ea sed duo diam
                      ipsum, clita at justo, lorem amet vero eos sed sit...
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-5.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-1.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="position-relative mb-3">
                  <img
                    class="img-fluid w-100"
                    src="img/news-500x280-6.jpg"
                    style="object-fit: cover"
                  />
                  <div class="overlay position-relative bg-light">
                    <div class="mb-2" style="font-size: 14px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h4" href=""
                      >Est stet amet ipsum stet clita rebum duo</a
                    >
                    <p class="m-0">
                      Rebum dolore duo et vero ipsum clita, est ea sed duo diam
                      ipsum, clita at justo, lorem amet vero eos sed sit...
                    </p>
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-2.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
                <div class="d-flex mb-3">
                  <img
                    src="img/news-100x100-3.jpg"
                    style="width: 100px; height: 100px; object-fit: cover"
                  />
                  <div
                    class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style="height: 100px"
                  >
                    <div class="mb-1" style="font-size: 13px">
                      <a href="">Technology</a>
                      <span class="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a class="h6 m-0" href=""
                      >Lorem ipsum dolor sit amet consec adipis elit</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 pt-3 pt-lg-0"> -->
    <!-- Social Follow Start -->
    <!-- <div class="pb-3">
              <div class="bg-light py-2 px-4 mb-3">
                <h3 class="m-0">Follow Us</h3>
              </div>
              <div class="d-flex mb-3">
                <a
                  href=""
                  class="d-block w-50 py-2 px-3 text-white text-decoration-none mr-2"
                  style="background: #39569e"
                >
                  <small class="fab fa-facebook-f mr-2"></small
                  ><small>12,345 Fans</small>
                </a>
                <a
                  href=""
                  class="d-block w-50 py-2 px-3 text-white text-decoration-none ml-2"
                  style="background: #52aaf4"
                >
                  <small class="fab fa-twitter mr-2"></small
                  ><small>12,345 Followers</small>
                </a>
              </div>
              <div class="d-flex mb-3">
                <a
                  href=""
                  class="d-block w-50 py-2 px-3 text-white text-decoration-none mr-2"
                  style="background: #0185ae"
                >
                  <small class="fab fa-linkedin-in mr-2"></small
                  ><small>12,345 Connects</small>
                </a>
                <a
                  href=""
                  class="d-block w-50 py-2 px-3 text-white text-decoration-none ml-2"
                  style="background: #c8359d"
                >
                  <small class="fab fa-instagram mr-2"></small
                  ><small>12,345 Followers</small>
                </a>
              </div>
              <div class="d-flex mb-3">
                <a
                  href=""
                  class="d-block w-50 py-2 px-3 text-white text-decoration-none mr-2"
                  style="background: #dc472e"
                >
                  <small class="fab fa-youtube mr-2"></small
                  ><small>12,345 Subscribers</small>
                </a>
                <a
                  href=""
                  class="d-block w-50 py-2 px-3 text-white text-decoration-none ml-2"
                  style="background: #1ab7ea"
                >
                  <small class="fab fa-vimeo-v mr-2"></small
                  ><small>12,345 Followers</small>
                </a>
              </div>
            </div> -->
    <!-- Social Follow End -->

    <!-- Newsletter Start -->
    <!-- <div class="pb-3">
              <div class="bg-light py-2 px-4 mb-3">
                <h3 class="m-0">Newsletter</h3>
              </div>
              <div class="bg-light text-center p-4 mb-3">
                <p>
                  Aliqu justo et labore at eirmod justo sea erat diam dolor diam
                  vero kasd
                </p>
                <div class="input-group" style="width: 100%">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    placeholder="Your Email"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary">Sign Up</button>
                  </div>
                </div>
                <small>Sit eirmod nonumy kasd eirmod</small>
              </div>
            </div> -->
    <!-- Newsletter End -->

    <!-- Ads Start -->
    <!-- <div class="mb-3 pb-3">
              <a href=""
                ><img class="img-fluid" src="img/news-500x280-4.jpg" alt=""
              /></a>
            </div> -->
    <!-- Ads End -->

    <!-- Popular News Start -->
    <!-- <div class="pb-3">
              <div class="bg-light py-2 px-4 mb-3">
                <h3 class="m-0">Tranding</h3>
              </div>
              <div class="d-flex mb-3">
                <img
                  src="img/news-100x100-1.jpg"
                  style="width: 100px; height: 100px; object-fit: cover"
                />
                <div
                  class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                  style="height: 100px"
                >
                  <div class="mb-1" style="font-size: 13px">
                    <a href="">Technology</a>
                    <span class="px-1">/</span>
                    <span>January 01, 2045</span>
                  </div>
                  <a class="h6 m-0" href=""
                    >Lorem ipsum dolor sit amet consec adipis elit</a
                  >
                </div>
              </div>
              <div class="d-flex mb-3">
                <img
                  src="img/news-100x100-2.jpg"
                  style="width: 100px; height: 100px; object-fit: cover"
                />
                <div
                  class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                  style="height: 100px"
                >
                  <div class="mb-1" style="font-size: 13px">
                    <a href="">Technology</a>
                    <span class="px-1">/</span>
                    <span>January 01, 2045</span>
                  </div>
                  <a class="h6 m-0" href=""
                    >Lorem ipsum dolor sit amet consec adipis elit</a
                  >
                </div>
              </div>
              <div class="d-flex mb-3">
                <img
                  src="img/news-100x100-3.jpg"
                  style="width: 100px; height: 100px; object-fit: cover"
                />
                <div
                  class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                  style="height: 100px"
                >
                  <div class="mb-1" style="font-size: 13px">
                    <a href="">Technology</a>
                    <span class="px-1">/</span>
                    <span>January 01, 2045</span>
                  </div>
                  <a class="h6 m-0" href=""
                    >Lorem ipsum dolor sit amet consec adipis elit</a
                  >
                </div>
              </div>
              <div class="d-flex mb-3">
                <img
                  src="img/news-100x100-4.jpg"
                  style="width: 100px; height: 100px; object-fit: cover"
                />
                <div
                  class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                  style="height: 100px"
                >
                  <div class="mb-1" style="font-size: 13px">
                    <a href="">Technology</a>
                    <span class="px-1">/</span>
                    <span>January 01, 2045</span>
                  </div>
                  <a class="h6 m-0" href=""
                    >Lorem ipsum dolor sit amet consec adipis elit</a
                  >
                </div>
              </div>
              <div class="d-flex mb-3">
                <img
                  src="img/news-100x100-5.jpg"
                  style="width: 100px; height: 100px; object-fit: cover"
                />
                <div
                  class="w-100 d-flex flex-column justify-content-center bg-light px-3"
                  style="height: 100px"
                >
                  <div class="mb-1" style="font-size: 13px">
                    <a href="">Technology</a>
                    <span class="px-1">/</span>
                    <span>January 01, 2045</span>
                  </div>
                  <a class="h6 m-0" href=""
                    >Lorem ipsum dolor sit amet consec adipis elit</a
                  >
                </div>
              </div>
            </div> -->
    <!-- Popular News End -->

    <!-- Tags Start -->
    <!-- <div class="pb-3">
              <div class="bg-light py-2 px-4 mb-3">
                <h3 class="m-0">Tags</h3>
              </div>
              <div class="d-flex flex-wrap m-n1">
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Politics</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Business</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Corporate</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Sports</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Health</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Education</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Science</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Technology</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Foods</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Entertainment</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Travel</a
                >
                <a href="" class="btn btn-sm btn-outline-secondary m-1"
                  >Lifestyle</a
                >
              </div>
            </div> -->
    <!-- Tags End -->
    <!-- </div>
        </div>
      </div>
    </div> -->
    <!-- News With Sidebar End -->
  </div>
  <!-- Footer End -->

  <!-- Back to Top -->
</template>

<script>
import carousel from "vue-owl-carousel";
import ViewMatch from "./page/ViewMatch.vue";
import TopLeague from "./components2/TopLeague.vue";
import MostPlayed from "./components2/MostPlayed.vue";
import Upcoming from "./components2/Upcoming.vue";
import HighLight from "./components2/HighLight.vue";
export default {
  components: {
    carousel,
    ViewMatch,
    TopLeague,
    MostPlayed,
    Upcoming,
    HighLight,
  },
  mounted() {
    console.log("locale ", this.$i18n);
  },
  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    changeLanguage(obj) {
      localStorage.setItem("language", obj.target.value);
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>
